export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  dates?: string[];
  stealthSOCGraph?: (number | null)[];
  stealthVoltageGraph?: (number | null)[];
  stealthTempGraph?: (number | null)[];
  loadGraph?: (number | null)[];
  shorelineArray?: Date[];
  status?: string;
  stealthSOC?: number;
  stealthVoltage?: number;
  load?: number;
  stealthTemperature?: number;
  lastCharge?: number;
  stealthHours: number;
  totalChargingHours: number;
  assetNumber?: string;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
